/*  ==========================================================================
    QUICK REQUEST V2
    ========================================================================== */

@mixin quick-request--v2() {
  height: calc(65% + 35px);
  margin-top: -40px;
  position: fixed;
  right: 0;
  top: 35%;
  width: 250px;
  z-index: $unit__zi--quick-request;
  .bn-quick-request__title,
  .bn-quick-request__form {
    background-color: $clr__ci--primary;
    border: 1px solid $clr__typo--secondary;
    border-right: 0;
    color: $clr__typo--secondary;
    width: 100%;
  }
  .bn-quick-request__title {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 80px;
    text-align: right;
    > div {
      padding: 0 80px 0 20px;
      position: relative;
      width: 100%;
    }
    span {
      font-size: 1.8em;
      text-transform: uppercase;
    }
    .bn-quick-request__arrows {
      position: absolute;
      right: 20px;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      .bn-icon {
        font-size: 3.5em;
        height: 40px;
        line-height: 40px;
        &.bn-icon--arrow-bordered-top {
          display: none;
        }
      }
    }
    &.bn-quick-request__title--act {
      .bn-quick-request__arrows {
        .bn-icon {
          &.bn-icon--arrow-bordered-bottom {
            display: none;
          }
          &.bn-icon--arrow-bordered-top {
            display: inline-block;
          }
        }
      }
    }
    &:hover {
      background-color: $clr__ci--secondary;
    }
  }
  .bn-quick-request__form {
    @include scrollbar($clr__scrollbar,5px);
    height: calc(100% - 85px);
    display: none;
    margin-top: 5px;
    overflow-y: auto;
    padding: 10px 20px;
    > div {
      @include quick-request--vertical();
    }
    .bn-loader {
      color: $clr__typo--secondary;
    }
    &.bn-quick-request__form--visible {
      display: block;
    }
  }
  &.bn-quick-request--hideable {
    &.bn-quick-request--hide {
      display: none;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // 900px max height
  @media #{$mq__max-h--900} {
    height: calc(55% + 35px);
    top: 45%;
  }
  // 700px max height
  @media #{$mq__max-h--700} {
    height: calc(50% + 35px);
    top: 50%;
  }
  // 600px max height
  @media #{$mq__max-h--600} {
    display: none !important;
  }
  // tablet
  @media #{$mq__tablet} {
    display: none !important;
  }
}