/*  ==========================================================================
    REQUEST
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__request--bg: $clr__ci--primary;

/*
 * MODULE
 */

.bn-request {
  .bn-request__holder {
    background-color: rgba($module-clr__request--bg,0.1);
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */