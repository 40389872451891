/*  ==========================================================================
    SLIDE BOX
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__slide-box--border: $clr__typo--primary;
$module-clr__slide-box--headline: $clr__typo--primary;
$module-clr__slide-box--text: $clr__typo--primary;
$module-clr__slide-box--accent: $clr__ci--tertiary;

/*
 * MODULE
 */

.bn-slide-box {
  .bn-slide-box__head {
    border-bottom: 1px solid $module-clr__slide-box--border;
    cursor: pointer;
    padding: 10px 0;
    position: relative;
    .bn-slide-box__headline {
      @include typo--subheadline($module-clr__slide-box--headline);
      text-transform: none;
    }
    .bn-slide-box__state {
      color: $module-clr__slide-box--border;
      font-size: 2.5em;
      position: absolute;
      right: 0;
      text-align: right;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      z-index: 5;
      .bn-icon {
        display: none;
      }
    }
    &:hover {
      border-bottom-color: $module-clr__slide-box--accent;
      .bn-slide-box__headline {
        color: $module-clr__slide-box--accent;
      }
      .bn-slide-box__state {
        color: $module-clr__slide-box--accent;
      }
    }
  }
  .bn-slide-box__content {
    border-bottom: 1px solid $module-clr__slide-box--border;
    padding: 20px 0;
    .bn-slide-box__text {
      @include typo--rte($module-clr__slide-box--text,$clr__typo--primary);
    }
    .bn-slide-box__images {
      .bn-slide-box__image {
        display: block;
        img {
          @include img--responsive();
        }
        > div {
          overflow: hidden;
        }
      }
    }
    .bn-slide-box__button {
      padding-top: 20px;
      text-align: right;
      .bn-button {
        @include button--default();
      }
    }
  }
  // with images
  &.bn-slide-box--pictorial {
    .bn-slide-box__content {
      .bn-slide-box__text {
        padding-left: 20px;
        order: 2;
        + .bn-slide-box__images {
          max-width: 25%;
          order: 1;
          .bn-slide-box__image {
            margin-top: 20px;
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
    // only images
    &.bn-slide-box--no-text {
      .bn-slide-box__content {
        padding: 15px 0;
        .bn-slide-box__images {
          @include clearfix();
          .bn-slide-box__image {
            float: left;
            padding: 5px;
            width: 33.33%;
          }
        }
      }
    }
  }
  // accordion
  &.bn-accordion {
    .bn-slide-box__head {
      &.bn-accordion__trigger {
        padding-right: 40px;
        .bn-slide-box__state {
          .bn-icon {
            &.bn-icon--arrow-bottom {
              display: inline-block;
            }
          }
        }
        &.bn-accordion__trigger--act {
          .bn-slide-box__state {
            .bn-icon {
              &.bn-icon--arrow-bottom {
                display: none;
              }
              &.bn-icon--close {
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .bn-slide-box__content {
      &.bn-accordion__slide {
        display: none;
        overflow: hidden;
        &.bn-accordion__slide--open {
          display: block;
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-slide-box {
    // with images
    &.bn-slide-box--pictorial {
      .bn-slide-box__content {
        > .bn-grid__row {
          display: block;
        }
        .bn-slide-box__text {
          padding-left: 0;
          order: 1;
          + .bn-slide-box__images {
            margin-top: 20px;
            max-width: 100%;
            order: 2;
            .bn-slide-box__image {
              margin-top: 10px;
            }
          }
        }
      }
      // only images
      &.bn-slide-box--no-text {
        .bn-slide-box__content {
          .bn-slide-box__images {
            .bn-slide-box__image {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-slide-box {
    // with images
    &.bn-slide-box--pictorial {
      // only images
      &.bn-slide-box--no-text {
        .bn-slide-box__content {
          .bn-slide-box__images {
            .bn-slide-box__image {
              float: none;
              padding-left: 0;
              padding-right: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}