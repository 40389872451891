/*  ==========================================================================
    COLORS
    List of all colors concerning your project.

    INFO:
    - change your project related colors here
    - if needed change also the specific colors
    - don't delete colors, only add (if possible here: custom)!
    - for naming help of additional custom colors use
      "http://chir.ag/projects/name-that-color/#000000"
    ========================================================================== */

/*
 * GENERAL
 */

$clr__black: #000000;
$clr__bn-red: #AE173B;
$clr__gray: #808080;
$clr__white: #FFFFFF;


 /* PROJECT RELATED*/
 $clr__greytone1: #989898;
 $clr__greytone2: #EFEFEF;
 $clr__greytone3: #626262;
 $clr__greytone4: #9F9F9F;
 $clr__greentone1: #468981;
 $clr__greentone2: #01AE85;
 $clr__greentone2: #05AF87;
 $clr__blacktone1: #030303;
 $clr__blacktone1: #000000;
 $clr__blacktone1: #0A1116;
 $clr__yellowtone1: #F5C419;
 $clr__yellowtone2: #FFD800;
 $clr__yellowtone2: #ED6946;



// corporate identity
$clr__ci--primary: #fff;
$clr__ci--secondary: #EFEFEF;
$clr__ci--tertiary: #468981;

// typography
$clr__typo--primary: #030303;
$clr__typo--secondary: #FFFFFF;
$clr__typo--tertiary: #468981;

// call to action
$clr__cta--primary: #DDA741;

// notification
$clr__correct: #008000;
$clr__error: #FF0000;

/*
 * SPECIFIC
 */

$clr__page: $clr__white;
$clr__progress-bar: $clr__ci--tertiary;
$clr__scrollbar: $clr__ci--tertiary;
$clr__selection: $clr__bn-red;

/*
 * CUSTOM
 */

